<template>
  <div id="companyForm" class="form">
    <v-form v-if="loaded" ref="form" v-model="valid" lazy-validation>
      <v-text-field v-model="company.name" label="Name" :required="true" />
      <v-text-field v-model="company.thaiName" label="Thai Name" :required="true" />
      <v-text-field v-model="company.chineseName" label="Chinese Name" :required="true" />

      <v-select
        v-model="company.type"
        :items="company.relations.type"
        item-value="id"
        item-text="name"
        label="Type"
        chips
        deletable-chips
      ></v-select
      ><v-text-field v-model="company.email" label="Email" :required="false" />
      <v-textarea
        v-model="company.address"
        rows="2"
        label="Address"
        :required="false"
      />
      <v-text-field
        v-model="company.phone1"
        label="Phone 1"
        :required="false"
      />
      <v-text-field
        v-model="company.phone2"
        label="Phone 2"
        :required="false"
      />

      <div class="mt-4 d-flex justify-space-between">
        <v-btn @click="goBack"> Back </v-btn>
        <v-btn @click="handleSubmit" color="primary">
          {{ id ? "Update" : "Save" }}
        </v-btn>
        <span></span>
      </div>
    </v-form>
  </div>
</template>

<script>
import { getCompany } from "@/services/company";

import { mapActions } from "vuex";

export default {
  props: ["id"],
  data() {
    return {
      loaded: false,
      valid: false,
      company: {
        relations: {
          type: [{name: "Customer", id: 'CUSTOMER'}, {name: "Supplier", id: 'SUPPLIER'}],
        },
        name: "",
        thaiName: "",
        chineseName: "",
        type: "",
        email: "",
        address: "",
        phone1: "",
        phone2: "",
      },
    };
  },
  created() {
    this.setInstance();
  },
  methods: {
    ...mapActions("company", ["createCompany", "updateCompany"]),
    ...mapActions("messages", [
      "addMessage",
      "addErrorMessage",
      "addSuccessMessage",
    ]),

    async handleSubmit() {
      if (this.id) {
        let company = this.company;
        delete company.relations;

        this.updateCompany(company)
          .then((response) => {
            console.log(response);
            this.addSuccessMessage("Company was updated successfully");
            this.goBack();
          })
          .catch(() => {
            this.addErrorMessage(
              "Error updating Company, please try again later"
            );
          });
      } else {
        let company = this.company;
        delete company.relations;

        this.createCompany(company)
          .then((response) => {
            console.log(response);
            this.addSuccessMessage("Company was created successfully");
            this.goBack();
          })
          .catch(() => {
            this.addErrorMessage(
              "Error creating Company, please try again later"
            );
          });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    setInstance() {
      if (this.id) {
        this.company.id = this.id;
        getCompany(this.id).then((response) => {
          let instance = response.data;
          for (let property in instance) {
            if (
              Object.prototype.hasOwnProperty.call(instance, property) &&
              Object.prototype.hasOwnProperty.call(this.company, property)
            ) {
              this.company[property] = instance[property];
            }
          }
          this.loaded = true;
        });
      } else {
        this.loaded = true;
      }
      this.setDependencies();
    },
    setDependencies() {},
  },
  components: {},
};
</script>
